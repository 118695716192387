<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="70"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="5">
            <FormItem label="商品ID:" prop="ProductIDs">
              <Input placeholder="多个ID逗号隔开" v-model="params.ProductIDs" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品编号:" prop="Code">
              <Input placeholder="请输入商品编号" v-model="params.Code" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="BrandID" label="商品品牌:">
              <Select clearable v-model="params.BrandID" placeholder="商品品牌">
                <Option
                  v-for="(item, index) in ProductBrandInfo"
                  :key="index"
                  :style="{ marginLeft: item.Layer * 20 + 'px' }"
                  :value="item.ID"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="ClassCode" label="商品分类:">
              <Select
                clearable
                v-model="params.ClassCode"
                placeholder="商品分类"
              >
                <Option
                  v-for="(item, index) in ProductClassInfo"
                  :key="index"
                  :value="item.Code"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="5">
            <FormItem label="商品名称:" prop="Name">
              <Input placeholder="请输入商品名称" v-model="params.Name" />
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem prop="ProductType" label="商品类型:">
              <Select
                clearable
                v-model="params.ProductType"
                placeholder="商品类型"
              >
                <Option :value="10">普通商品</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品栏目:" prop="Topic">
              <Select clearable v-model="params.Topic" placeholder="商品栏目">
                <Option
                  v-for="(item, index) in ProductTopicEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="5">
            <FormItem label="商品状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="商品状态">
                <Option
                  v-for="(item, index) in ProductStatusEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="4">
            <FormItem label="是否售罄:" prop="IsSellOut">
              <Select
                clearable
                v-model="params.IsSellOut"
                placeholder="是否售罄"
              >
                <Option
                  v-for="(item, index) in ProductIsSellOutEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['Product_Edit'])"
            @click="handleOpenAdd"
          >
            新增
          </Button>
          <Button
            type="warning"
            class="ml2"
            v-if="$canAction(['Sys_Cache_Edit'])"
            @click="$importCache('AllProduct', $data)"
          >
            更新缓存
            <i>{{ cacheUpdatedTime }}</i>
          </Button>
          <Button
            type="primary"
            class="ml2"
            icon="md-arrow-round-up"
            :disabled="selection.length <= 0"
            v-if="$canAction(['Product_Edit'])"
            @click="handleOpenUpdateState('UP')"
          >
            批量上架
          </Button>
          <Button
            type="primary"
            class="ml2"
            icon="md-arrow-round-down"
            v-if="$canAction(['Product_Edit'])"
            :disabled="selection.length <= 0"
            @click="handleOpenUpdateState('DOWN')"
          >
            批量下架
          </Button>
          <Button
            type="error"
            icon="md-trash"
            class="ml2"
            :disabled="selection.length <= 0"
            v-if="$canAction(['Product_Delete'])"
            @click="handleOpenBatchDelete"
          >
            批量删除
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      stripe
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      @on-selection-change="selectionClick"
    >
      <template slot="Image" slot-scope="{ row }">
        <Tooltip placement="right">
          <Avatar
            :src="$setImagePrefix(row.DefaultImagePath, 'product')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
            shape="square"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.DefaultImagePath, 'product')"
              height="230"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>
      <template slot="BrandID" slot-scope="{ row }">
        <span>{{ brandText(row) }}</span>
      </template>
      <template slot="ClassCode" slot-scope="{ row }">
        <span>{{ classText(row) }}</span>
      </template>
      <template slot="Status" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.Status === 10
              ? 'warning'
              : row.Status === 20
              ? 'green'
              : row.Status === 30
              ? 'red'
              : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          class="ml"
          type="primary"
          v-if="$canAction(['Product_Search'])"
          @click="handleOpenLook(row.ID)"
        >
          查看
        </Button>
        <Button
          size="small"
          class="ml2"
          type="success"
          v-if="$canAction(['Product_Edit'])"
          @click="handleOpenEdit(row.ID)"
        >
          编辑
        </Button>
        <Button
          size="small"
          class="ml2"
          type="warning"
          v-if="$canAction(['Product_Edit'])"
          @click="handleOpenCopy(row)"
        >
          复制
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <add-product-modal @on-save="onUpdated" ref="addProductModal" />
    <product-info-drawer ref="productInfoDrawer" />
  </Layout>
</template>
<script>
import Layout from "@/components/layout/index.vue";
import api from "@/api";
import AddProductModal from "./components/add-product-modal.vue";
import { mapActions } from "vuex";
import bizEnum from "@/libs/bizEnum";
import productInfoDrawer from "./components/product-info-drawer.vue";
export default {
  name: "ProductManage",
  data() {
    return {
      NewClass: [],
      tableLoading: false,
      cacheUpdatedTime: "",
      tableHeight: 0,
      productRow: {},
      selection: [], // 当前选中行(删除参数)
      ModelNewClass: [],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      params: {
        Topic: "",
        Code: "",
        Name: "",
        ProductIDs: "",
        ClassCode: "",
        BrandID: "",
        IsSellOut: "",
        Status: null,
        IsSearchTest: 1,
        IsChild: 1,
        ProductType: null,
        Boss: 1,
        Page: 1,
        PageSize: 40,
        SortName: "ID",
        SortOrder: "DESC",
      },
      columns: [
        { type: "selection", width: 58, align: "center" },
        {
          title: "商品图片",
          key: "IconPath",
          minWidth: 75,
          align: "center",
          slot: "Image",
        },
        {
          title: "商品ID",
          key: "ID",
          minWidth: 111,
          align: "center",
          sortable: "custom",
        },
        {
          title: "商品编号",
          key: "Code",
          minWidth: 140,
          align: "left",
          sortable: "custom",
        },
        {
          title: "商品名称",
          key: "Name",
          minWidth: 140,
          align: "left",
          sortable: "custom",
          ellipsis: true,
        },
        {
          title: "商品品牌",
          key: "BrandID",
          slot: "BrandID",
          align: "left",
          minWidth: 100,
        },
        {
          title: "商品分类",
          key: "ClassCode",
          slot: "ClassCode",
          align: "left",
          minWidth: 100,
        },
        {
          title: "状态",
          key: "Status",
          slot: "Status",
          align: "center",
          minWidth: 60,
          sortable: "custom",
        },
        {
          title: "创建时间",
          key: "CreateDate",
          width: 135,
          align: "center",
          sortable: "custom",
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
        fixed: "right",
      });
    }
    this.LoadList();
    this.getProductBrandInfo();
    this.getProductClassInfo();
    this.$getCacheUpdatedTime("AllProduct", this);
  },
  components: { AddProductModal, productInfoDrawer, Layout },
  methods: {
    ...mapActions(["getProductBrandInfo", "getProductClassInfo"]),
    handleSearch() {
      this.params.Page = 1;
      this.LoadList();
    },
    brandText(row) {
      let temp =
        this.ProductBrandInfo &&
        this.ProductBrandInfo.find((item) => {
          return String(item.ID) === String(row.BrandID);
        });
      return temp ? temp.Name : "";
    },
    classText(row) {
      let temp = [];
      if (this.ProductClassInfo) {
        const classIDArr = row.ClassCode.split(",");
        temp = this.ProductClassInfo.filter((item) =>
          classIDArr.includes(item.Code)
        );
      }
      return temp.map((item) => item.Name).join(",");
    },
    BrandSelectChange(value, keepValue) {
      const _this = this;
      _this.ModelNewClass = [];
      if (_this.params) {
        _this.params.BrandID = value;
        _this.ProductClassInfo.map((item) => {
          if (item.BrandID) {
            const TempList = item.BrandID.split(",");
            TempList.forEach((cell) => {
              const BrandID = parseInt(cell);
              if (BrandID === parseInt(value)) {
                _this.ModelNewClass.push(item);
              }
            });
          }
        });
        if (!keepValue) _this.obj.ClassCode = null;
      }
    },
    async LoadList() {
      this.tableLoading = true;
      try {
        const res = await api.GetProductList(this.params);
        if (res.Status === 100) {
          this.resObj = res.Data;
          this.tableLoading = false;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.LoadList();
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.LoadList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.LoadList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.LoadList();
    },
    handleOpenAdd() {
      this.$refs.addProductModal.$refs.ProductForm.resetFields();
      this.$refs.addProductModal.obj = {
        Attribute: 10,
        ProductMedia: [],
        ProductContent: [],
        MinNum: 0,
        MaxNum: 0,
        Coefficient: 1,
        CheckBonus: true,
      };
      this.$refs.addProductModal.copyID = null;
      this.$refs.addProductModal.isEdit = false;
      this.$refs.addProductModal.isAdd = true;
      this.$refs.addProductModal.isShow = true;
    },
    async handleOpenEdit(id) {
      try {
        const res = await api.GetProductInfoById({
          id: id,
        });
        if (res.Status === 100) {
          if (res.Data.Topic) res.Data.Topic = res.Data.Topic.split(",");
          else res.Data.Topic = [];
          res.Data.IsSellOut = res.Data.IsSellOut || false;
          this.$refs.addProductModal.$refs.ProductForm.resetFields();
          this.$refs.addProductModal.obj = res.Data;
          this.$refs.addProductModal.copyID = null;
          this.$refs.addProductModal.isEdit = true;
          this.$refs.addProductModal.isAdd = false;
          this.$refs.addProductModal.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        // TODO: 记录错误日志
      }
    },
    async handleOpenLook(id) {
      const res = await api.GetProductInfoById({
        id: id,
      });
      if (res.Status === 100) {
        if (res.Data.Topic) res.Data.Topic = res.Data.Topic.split(",");
        res.Data.IsSellOut = res.Data.IsSellOut || false;
        this.$refs.addProductModal.copyID = null;
        this.$refs.productInfoDrawer.isShow = true;
        this.$refs.productInfoDrawer.obj = res.Data;
      } else {
        this.$Message.error(res.Msg);
      }
    },
    async handleOpenCopy(row) {
      try {
        const res = await api.GetProductInfoById({
          id: row.ID,
        });
        if (res.Status === 100) {
          let id = res.Data.ID;
          if (res.Data.Topic) res.Data.Topic = res.Data.Topic.split(",");
          res.Data.IsSellOut = res.Data.IsSellOut || false;
          res.Data.ID = 0;
          res.Data.Stock = 0;
          res.Data.TotalStock = 0;
          res.Data.Sells = 0;
          //res.Data.Status = 0;
          this.$refs.addProductModal.obj = res.Data;
          this.$refs.addProductModal.copyID = id;
          this.$refs.addProductModal.isEdit = false;
          this.$refs.addProductModal.isAdd = true;
          this.$refs.addProductModal.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async Copy(id) {
      try {
        const res = await api.CopyProduct({ id: id });
        if (res.Data) {
          // this.delayLoadList()
          this.$Message.success("复制成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
      }
    },
    async handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除【" + row.Name + "】吗？",
        loading: true,
        onOk: function () {
          _this.Delete(row);
        },
      });
    },
    onUpdated(row) {
      row.Topic = row.Topic.split(",");
      if (row.ID > 0) {
        const index = this.resObj.DataList.findIndex(
          (item) => item.ID === row.ID
        );
        if (index >= 0) {
          this.resObj.DataList.splice(index, 1, row);
        } else {
          this.resObj.DataList.unshift(row);
        }
      }
    },
    async Delete(row) {
      try {
        const res = await api.DeleteProduct({
          ids: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          this.resObj.DataList.splice(index, 1, row);
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    handleOpenUpdateState(type) {
      // 批量下架
      if (this.selection.length <= 0) {
        this.$Message.error("请先选择需要修改的行！");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        loading: true,
        content: "<p>确定要修改选中行的状态?</p>",
        onOk: () => {
          this.UpdateState(type);
        },
        onCancel: () => {},
      });
    },
    async UpdateState(type) {
      const _this = this;
      try {
        if (type === "UP") {
          const res = await api.BatchUpdateProductStatus({
            upids: this.selection,
          });
          if (res.Status === 100) {
            this.$Message.success("操作成功");
            this.selection.forEach((item) => {
              let product = _this.resObj.DataList.find((it) => it.ID === item);
              let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
              product.Status = 20;
              product.StatusCN = "上架";
              if (product) {
                this.resObj.DataList.splice(ix, 1, product);
              }
            });
            this.$Modal.remove();
          } else {
            this.$Message.error(res.Msg);
            this.$Modal.remove();
          }
        } else {
          const res = await api.BatchUpdateProductStatus({
            donids: this.selection,
          });
          if (res.Status === 100) {
            this.$Message.success("操作成功");
            this.selection.forEach((item) => {
              let product = _this.resObj.DataList.find((it) => it.ID === item);
              let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
              product.Status = 30;
              product.StatusCN = "下架";
              if (product) {
                this.resObj.DataList.splice(ix, 1, product);
              }
            });
            this.$Modal.remove();
          } else {
            this.$Message.error(res.Msg);
            this.$Modal.remove();
          }
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    async handleOpenBatchDelete() {
      if (this.selection.length <= 0) {
        this.$Message.error("请先选择需要修改的行！");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除选中行吗？",
        loading: true,
        onOk: () => {
          this.BatchDelete();
        },
      });
    },
    async BatchDelete() {
      const _this = this;
      try {
        const res = await api.DeleteProduct({
          ids: this.selection,
        });
        if (res.Status === 100) {
          this.$Message.success("操作成功");
          this.selection.forEach((item) => {
            let product = _this.resObj.DataList.find((it) => it.ID === item);
            let ix = _this.resObj.DataList.findIndex((it) => it.ID === item);
            product.Status = -99;
            product.StatusCN = "已删除";
            if (product) {
              this.resObj.DataList.splice(ix, 1, product);
            }
          });
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    // 选项发生改变方法
    selectionClick(selection) {
      const _this = this;
      // if (selection.length > 0) _this.WaitDelete = true
      // else _this.WaitDelete = false
      _this.selection = [];
      selection.map((item) => {
        _this.selection.push(item.ID);
      });
    },
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.$getCacheUpdatedTime("AllProduct", this);
        this.LoadList();
      }
    });
  },
  activated() {
    this.$getCacheUpdatedTime("AllProduct", this);
    const self = this;
    window.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.LoadList();
      }
    });
  },
  deactivated() {
    window.removeEventListener("keydown", function () {});
  },
  computed: {
    ProductBrandInfo() {
      return this.$store.state.product.brandList;
    },
    ProductClassInfo() {
      return this.$store.state.product.classList;
    },
    ProductTopicEnum() {
      return bizEnum.ProductTopicEnum;
    },
    ProductStatusEnum() {
      return bizEnum.ProductStatusEnum;
    },
    ProductIsSellOutEnum() {
      return bizEnum.ProductIsSellOutEnum;
    },
  },
};
</script>

<style lang="scss"></style>
